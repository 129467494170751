import React from "react";

const WorkWUText = (props) => {
    return(
        <React.Fragment>
            <div className="span-1">
                <h1 className="text-title-descripcion">
                    Trabaja con nosotros
                </h1>
                <p className="text-color-descripcion">
                    {props.text}
                </p>
            </div>
        </React.Fragment>
    )
}
export default WorkWUText;