const animateAutoSecuence = (currentIndex, directionNormal,setCurrentIndex,setDirectionNormal,longitudImages ) =>{
    //obtengo las variables de los estados para su modificación
    let current = currentIndex;
    let direction = directionNormal;

    if(current >= 3 && directionNormal) direction = false;//si entra en el if significa que tiene que ir a la dirección inverso

    if (current <= 0 && !directionNormal) direction = true;// si entra en if significa que tiene que ir a la dirección normal

    if (direction){//segun la dirección sumamos o restamos
        current = (current + 1)
    }else {
        current = (current - 1)
    }
    current = current % longitudImages;//calculamos la pocesión de current index para ver que imagen le toca
    //seteamos todos los estados según los valores modificados
    setCurrentIndex(current)
    setDirectionNormal(direction)
}
export default animateAutoSecuence;