import React from "react";
import ElementoCarta from "./ElementoCarta";

const SectionCarta = (props) => {
    return (
        <React.Fragment>
            {
                props.labelData.map((label, index) => {
                    // Modificar el valor de label en función de su valor original
                    let displayLabel = label;
                    if (label === 'Carnes') {
                        displayLabel = 'De los praos asturianos';
                    } else if (label === 'Pescados') {
                        displayLabel = 'Del Cantábrico y alrededores';
                    }

                    return (
                        <React.Fragment key={index}>
                            {label !== 'Vinos' && ( // Verifica si el label no es 'vino'
                                <React.Fragment>
                                    <h2 id={label} className="text-big2 text-center titulo-sectionCarta font-format-Gochi">{displayLabel}</h2>
                                    <div className="sectionCarta">
                                        <ElementoCarta label={label} dataBack={props.backData} />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    );
                })
            }
        </React.Fragment>
    );
}

export default SectionCarta;
