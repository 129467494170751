import React from "react";

const ElementoCarta = (props) => {
    return(
        <React.Fragment>
            {
                props.dataBack.map((element,index)=>(
                    (element.label === props.label)?
                        <div key={index} className="contenedorElemento-Carta">
                            <div>
                                <h3 className="text-small margenestitulos">{element.nombre}</h3>
                                <p>{element.precio}€</p>
                            </div>
                            {element.descripcion ? <p className="text-small2">{element.descripcion}</p> : ''}
                            <p className="text-small3 alergenos">
                                {element.alergenos}
                            </p>
                            <hr/>
                        </div>

                        :
                        ''
                ))
            }
        </React.Fragment>
    );
}
export default ElementoCarta