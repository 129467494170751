import './description.scss'
import {motion} from "framer-motion";
import React, {useEffect, useRef} from "react";
import goToAnchorTimeOut from "../../../utils/jsonUtils/goToAnchorTimeOut";
const DescriptionMainRest = (props) => {
    const refDescriptionSection = useRef(null);
    const refTimeout = useRef(null);
    const divDescriptionMotion = {
        hidden: { opacity: 0},
        show: { opacity: 1 ,transition: {duration: 1}}
    }
    useEffect(  ()=>{

        //función creado para ir a la ancla durante el cambio de la página recibe la referencia del elemento o la ancla
        refTimeout.current =  goToAnchorTimeOut(refDescriptionSection.current); // te devuelve el identificador del timeOut para que puedas destruir si el componente se destruye
        return () => {
            clearTimeout(refTimeout.current)
        }
    },[])
    return (
        <motion.div id="description" className="description-container" variants={divDescriptionMotion} initial="hidden" whileInView="show" viewport={{once: true, amount: 0.6}}>
            <h1 className="description-heading font-format-Gochi title-section">
                {props.title}
            </h1>
            <div className="description-content">
                <pre className="description-text font-format-Gochi text-small3">
                    {props.description}
                </pre>
            </div>
            {/*<div className="button-container">*/}
            {/*    <motion.button onClick={() => changePage(props.buttonurl)} className="button-attach font-format-Gochi buttonDescription" whileHover={{ scale: 1.2 }} transition={{ type: "spring", stiffness: 1000, damping: 5 }}>*/}
            {/*        Conócenos!*/}
            {/*    </motion.button>*/}
            {/*</div>*/}
        </motion.div>

    );
}
export default DescriptionMainRest;