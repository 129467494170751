//metodo para ir hacia la ancla si existe el elemento
const goToAnchorTimeOut = (elementHtml) => {
    const scrollHanddle = () => {
        if(window.location.hash) {//obtengo la ancla es decir #sectionGoogle
            // Obtiene el ID del ancla
            const anchor = window.location.hash.substring(1);// con esto obtengo sectionGoogle
            // Busca el elemento con ese ID
            const element = elementHtml;//si ya ha cargado el frontend este section google existe su etiqueta de html
            // Si encuentra el elemento, lo desplaza en la ventana visible
            if(element && (element.id === anchor)) {//miro si existe el element y verifico su id es igual a la ancla para dirijirse a ese apartado
                element.scrollIntoView();//
            }
        }
    }
    const idTimeOut = setTimeout(scrollHanddle,300);
    return idTimeOut;
}
export default goToAnchorTimeOut;