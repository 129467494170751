import React, {useContext, useEffect, useState} from "react";
import useCarta from "../../../../utils/Apis/productosApi/useCarta";
import LoadingScreen from "../../../LoadingScreens/LoadingScreen";
import './cartaLayout.scss'
import SectionCarta from "./CartaComida/SectionCarta";
import HeaderLayoutCarta from "./HeaderLayoutCarta";
import SectionCartaVinos from "./CartaVinos/SectionCartaVinos";
import {CartaContext} from "../../../../providers/CartaProvider";
import { motion } from 'framer-motion';
import {Helmet} from "react-helmet-async";
import getJson from "../../../../utils/jsonUtils/getJson";


const CartaMain = (props) => {
    const { selectCarta, setSelectCarta } = useContext(CartaContext);
    const code = props.code;
    const data = getJson(code);
    const [loading ,setLoading] = useState(true);
    const {dataLabels,dataCarta} = useCarta(data.conf.establecimiento,data.carta.conf.productoEnable);
    var headerMovStyles = null;

    const handleOptionChange = (option) => {
        setSelectCarta(option);
    };
    const handleResize = (e) => {
        headerMovStyles = document.querySelector(".header-movil");
        if(headerMovStyles){
            headerMovStyles.style.display = "none";
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            if(headerMovStyles){
                headerMovStyles.style.display= "flex";
            }
            window.removeEventListener('resize', handleResize);
        }
    },  []);


    return(

        <div>
            <Helmet>
                <link rel="canonical" href={"https://www.grupocasapijo.es/restaurant/" + code + "/carta/"}/>
                <title>{data.helmets.helmetCarta.titulo}</title>
                <meta name="description" content= {data.helmets.helmetCarta.descripcion}/>
                <meta property="og:title" content= {data.helmets.helmetCarta.titulo}/>
                <meta property="og:description" content= {data.helmets.helmetCarta.descripcion}/>
                <meta name="keywords" content= {data.helmets.helmetCarta.keywords}/>
                <meta property="og:type" content="website" />
            </Helmet>
            {
                (!loading&&dataCarta)?
                    <motion.main
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.4}}>
                        <div className=" p-4 general-container">
                            <div className="bg-carta ">
                                <HeaderLayoutCarta handleOptionChange={handleOptionChange} select={selectCarta} dataHeader={data}/>
                                {selectCarta === 1 && <SectionCarta labelData = {dataLabels} backData = {dataCarta}/>}
                                {selectCarta === 2 && <SectionCartaVinos labelData = {dataLabels} backData = {dataCarta}/>}
                            </div>
                        </div>
                    </motion.main>
                    :
                    <LoadingScreen call={setLoading}/>
            }
        </div>
    );

}
export default CartaMain;