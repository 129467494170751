import React from "react";
import ElementoCartaVinos from "../CartaVinos/ElementoCartaVinos";
import '../cartaLayout.scss'

const SectionCartaVinos = (props) => {
    const vinos = [];
    const infoVinos = [];
    const tiposVino = [];

    // Filtrar los elementos de props.backData que tienen label 'Vinos'
    props.backData.forEach((element) => {
        if(element.label === 'Vinos'){
            vinos.push(element);
        }
    });

    // Procesar cada elemento de vinos
    vinos.forEach((element) =>{
            const tipoArray = element.tipo.split('_');
            // Si no está vacío, intentar encontrar un vino similar en infoVinos
            const vinoSimilar = infoVinos.find((vino) => vino.nombre === element.nombre && vino.tipo === tipoArray[2]);

            // Si se encontró un vino similar, actualizar su precio
            if(vinoSimilar) {

                if(tipoArray[1] === 'copa'){
                    vinoSimilar.precioCopa = element.precio;
                } else {
                    vinoSimilar.precioBotella = element.precio;
                }
            } else {
                // Si no se encontró un vino similar, agregar uno nuevo a infoVinos
                const VinoShow = {
                    nombre: element.nombre,
                    tipo: '',
                    precioBotella: '',
                    precioCopa: '',
                    alergenos:''
                };
                const arrayTipo = element.tipo.split('_');
                if(arrayTipo[1] === 'copa'){
                    VinoShow.precioCopa = element.precio;
                } else {
                    VinoShow.precioBotella = element.precio;
                }
                VinoShow.tipo = arrayTipo[2];
                if (!tiposVino.includes(arrayTipo[2])) {
                    tiposVino.push(arrayTipo[2]);
                }
                infoVinos.push(VinoShow);
            }
    });
    return (
        <React.Fragment>
            {
                tiposVino.map((label,index) => (
                <React.Fragment key={index}>
                        <div  className="sectionCarta">
                            <h2 id={label} className="text-big2 text-center titulo-sectionCarta font-format-Gochi">{label}</h2>
                                <ElementoCartaVinos dataVinos={infoVinos} keyIndex = {label}/>
                        </div>

                </React.Fragment>
                ))
            }
        </React.Fragment>
    );
}

export default SectionCartaVinos;
