const sortMenu = (a,b) => {
    //Prioridades para ordenar los labels
    const ordenPrioridad = {
        primero: 1,
        segundo: 2,
        postre: 3,
        bebida: 4
    };
    const prioridadA = ordenPrioridad[a];
    const prioridadB = ordenPrioridad[b];
    // Si la prioridad de A es menor que la de B, A debe aparecer antes
    if (prioridadA < prioridadB) {
        return -1;
    }
    // Si la prioridad de A es mayor que la de B, A debe aparecer después
    if (prioridadA > prioridadB) {
        return 1;
    }
    // Si la prioridad de A y B es la misma, no hay cambio en el orden
    return 0;
}
export default sortMenu;