import React, {useEffect, useRef, useState} from "react";
import { motion } from 'framer-motion';
import postJobApplication from "../../../utils/Apis/Forms/postJobAplication";
import {useNavigate} from "react-router-dom";

const WorkWUForm = (props) => {
    const [fileName, setFileName] = useState("No hay archivos adjuntados");
    const textareaRef = useRef(null);
    const [overflowY, setOverflowY] = useState('hidden');
    const navigateToURL = useNavigate();
    const formRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        apellidos: '',
        email: '',
        movil: '',
        fecha: '',
        mensaje: '',
        file: ''
    });
    const [errors, setErrors] = useState({
        nombre: '',
        apellidos: '',
        email: '',
        movil: '',
        fecha: '',
        mensaje: '',
        file: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        if (id === "fecha") {
            const date = new Date(value);
            const stringDate = `${date.toLocaleDateString('en-US', { weekday: 'long' })} ${date.getDate()}/${date.toLocaleDateString('en-US', { month: 'long' })}/${date.getFullYear()}`;

            setFormData({...formData, [id]: stringDate });
        }
        else {
            setFormData({...formData, [id]: value });
        }
        setErrors({ ...errors, [id]: '' }); // Limpia el error cuando se cambia el valor del campo
    };

    const sendInfo = (e) => {
        e.preventDefault();
        let valid = true;
        const newErrors = {};

        // Validar que los campos no estén vacíos
        Object.keys(formData).forEach(key => {
            if (!formData[key]) {
                    newErrors[key] = `Asegúrese de rellenar todos los campos y adjuntar su CV antes de enviar su solicitud`;
                valid = false;
            }
        });

        setErrors(newErrors);

        if (!valid) return; // Si hay errores, no envía el formulario

        var formContent = [];

        Array.from(e.target.elements).forEach((element, index, array) => {
            if(element.name === "fecha"){
                formContent.push(new Date(element.value));
            }
            else if (element.id === "file") {
                formContent.push(formData.file);
            } else {
                formContent.push(element.value);
            }
        });

        postJobApplication(props.idRest, formContent[0], formContent[1],
            formContent[2], formContent[3], formContent[4],
            formContent[5],  formContent[6]).then(setShowPopup(true));
    };

    useEffect(() => {
        const handleResize = ()=> {
            const textarea = textareaRef.current;

            if (textarea.scrollHeight > textarea.clientHeight) {
                setOverflowY('scroll'); // Mostrar scroll
            } else {
                setOverflowY('hidden'); // Ocultar scroll
            }
        };
        // Agregar el event listener para manejar cambios en el contenido del textarea
        const textarea = textareaRef.current;
        textarea.addEventListener('input', handleResize);
        handleResize(); // Llamar a handleResize una vez para configurar el estado inicial

        // Limpiar el event listener cuando el componente se desmonte
        return () => {
            textarea.removeEventListener('input', handleResize);
        };

    }, []); // Se ejecuta solo una vez después de que el componente es montado

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer = reader.result;
            const uint8Array = new Uint8Array(arrayBuffer);
            const base64String = arrayBufferToBase64(uint8Array);
            setFormData({...formData, file: base64String });
        };
        reader.readAsArrayBuffer(file);
    };

    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };

    return(
        <React.Fragment>
            {showPopup && (
                <div className="popupWork">
                    <div className="divbuttonx">
                        <motion.button onClick={ () => {
                            setShowPopup(false);
                        }}
                                       className="button-close"
                                       whileHover={{ scale: 1.1 }}>
                            x
                        </motion.button>
                    </div>
                    <p>¡Mensaje enviado con éxito!</p>
                    <div>
                        <motion.button onClick={ () => {
                            navigateToURL(props.rutaHome);
                        }}
                                       className="button-attach"
                                       whileHover={{ scale: 1.2 }}
                                       transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                            Volver al menú
                        </motion.button>
                    </div>
                </div>
            )}
            {!showPopup &&(
                <div className="span-2 bg-fondo-form form text-color-form">
                    <form onSubmit={sendInfo} ref={formRef} encType="multipart/form-data">
                        <div className="grid-imputs">

                            <input type="text"
                                   id="nombre"
                                   name="nombre"
                                   className="inputs text-inputs"
                                   placeholder="Nombre"
                                   required
                                   onChange={handleChange}/>


                            <input type="text"
                                   id="apellidos"
                                   name="apellidos"
                                   className="inputs text-inputs"
                                   placeholder="Apellidos"
                                   required
                                   onChange={handleChange}/>
                        </div>
                        <div className="grid-imputs">

                            <input type="email"
                                   id="email"
                                   name="email"
                                   className="inputs text-inputs"
                                   placeholder="Email"
                                   required
                                   onChange={handleChange}/>



                            <input type="text"
                                   id="movil"
                                   name="movil"
                                   className="inputs text-inputs"
                                   placeholder="Número móvil"
                                   required
                                   onChange={handleChange}/>
                        </div>
                        <div className="inputs-withoutgrid">
                            <input type="date"
                                   id="fecha"
                                   name="fecha"
                                   className="inputs text-small4"
                                   placeholder="Fecha de nacimiento"
                                   required
                                   onChange={handleChange}/>
                            <label htmlFor="fecha" className="text-small4 font-format-Outfit label-fecha-nacimiento">  Fecha de nacimiento</label>
                        </div>

                        <textarea ref={textareaRef} id="mensaje" name="mensaje" className="text-inputs" style={{overflowY:overflowY}}
                                  placeholder="Mensaje" required
                                  onChange={handleChange}></textarea>

                        <div className="button-container-attach text-inputs">
                            <input id="file" name="file" type="file" className="input-file" onChange={handleFileChange} />
                            <label htmlFor="file" className="button-attach text-inputs">Adjuntar CV</label>
                            <label htmlFor="file">{fileName}</label>

                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="privacyPolicy"
                                name="privacyPolicy"
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="privacyPolicy">He leído y acepto la <a href="/privacy" target="_blank">política de privacidad</a></label>
                        </div>
                        <motion.button className="button-submit text-inputs" whileHover={{ scale: 1.2 }} transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                            Enviar
                        </motion.button>
                        {errors.file && <p className="error text-small3">{errors.file}</p>}
                    </form>
                </div>
            )}
        </React.Fragment>
    );
};

export default WorkWUForm;
