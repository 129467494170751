const  getDiaHabil = () => {
    const hoy = new Date();
    let diaSemana = hoy.getDay();

    if (diaSemana === 6 || diaSemana === 0) {  // Si es sábado o domingo
        diaSemana = 1;  // Avanza al lunes
    }

    return diaSemana;
}
export default getDiaHabil;