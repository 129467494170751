import React, {useEffect, useState} from "react";
import WorkWUText from "./WorkWUText";
import WorkWUForm from "./WorkWUForm";

import './workWU.scss'
import LoadingScreen from "../../LoadingScreens/LoadingScreen";
import getJson from "../../../utils/jsonUtils/getJson";
import { motion } from 'framer-motion';
import {Helmet} from "react-helmet-async";
const WorkWUContainer =  (props) => {
    const code = props.code;
    const data = getJson(code);
    const [loading ,setLoading] = useState(true);

    return(
        !loading&&data?
            <React.Fragment>
                {(data &&
                    <Helmet>
                        <link rel="canonical" href={"https://www.grupocasapijo.es/restaurant/" + code + "job/"}/>
                        <title>{data.helmets.helmetTrabajaConNosotros.titulo}</title>
                        <meta name="description" content= {data.helmets.helmetTrabajaConNosotros.descripcion}/>
                        <meta property="og:title" content= {data.helmets.helmetTrabajaConNosotros.titulo}/>
                        <meta property="og:description" content= {data.helmets.helmetTrabajaConNosotros.descripcion}/>
                        <meta name="keywords" content= {data.helmets.helmetTrabajaConNosotros.keywords}/>
                        <meta property="og:type" content="website" />
                    </Helmet>
                )}
                <motion.div className="bg-fondo-container container-main"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.4}}>

                    <div className="container-content m-auto">
                        <div className="grid-main-container">
                            <WorkWUText text={data.workwithus.text} idRest = {data.conf.establecimiento}/>{/* cargo el texto descriptivo en el componente*/}
                            <WorkWUForm rutaHome = {data.header.headerImage.url}/>
                        </div>
                    </div>
                </motion.div>
            </React.Fragment>
        :
            <LoadingScreen call={setLoading}/>
    )
}
export default WorkWUContainer;