import { motion } from "framer-motion";
import React, {useEffect, useState} from "react";
import './cartaLayout.scss';
import LogoHeader from "../../../header/LogoHeader";

const HeaderLayoutCarta = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const data = props.dataHeader;
    const [resize , setResize ] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = (e) => {
            setResize(window.innerWidth);
        }

        //se añade el event
        window.addEventListener('resize', handleResize);

        return () => {//cuando se desmonta el componente
            window.removeEventListener('resize', handleResize);
        };
    }, [resize]);
    const ulVariant = {
        show: {
            clipPath: isOpen
                ? "inset(0% 0% 0% 0% round 10px)"
                : "inset(10% 50% 90% 50% round 10px)",
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.5,
            }
        }
    };

    const liVariant = {
        show: isOpen ? { opacity: 1, scale: 1, filter: "blur(0px)" }
            : { opacity: 0, scale: 0.3, filter: "blur(20px)" },
    };

    const toggleVariant = {
        animate: { rotate: isOpen ? 180 : 0, transition: { duration: 0.2 } }
    };

    const handleItemClick = (option) => {
        props.handleOptionChange(option);
        setIsOpen(false); // Cerrar el menú después de hacer clic en un enlace
    };

    return (
        <header>
            <div className="container-header">
                {
                    (resize >= 850)?
                        <h1>Disfruta de nuestros {props.select == 1? "platos":"vinos"}</h1>
                        :
                        <div className="logo-header-carta">
                            <LogoHeader img={data.header.headerImage} />
                        </div>
                }


                <nav>
                    <motion.button whileTap={{ scale: 1 }} onClick={() => setIsOpen(!isOpen)}>
                        <motion.div className="toggle-menu" variants={toggleVariant} animate="animate">
                            <svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="20" fill="#cad2d7" />
                                <rect x="5" y="5" width="10" height="2" fill="#5e6f7c" />
                                <rect x="5" y="9" width="10" height="2" fill="#5e6f7c" />
                                <rect x="5" y="13" width="10" height="2" fill="#5e6f7c" />
                            </svg>
                        </motion.div>
                    </motion.button>
                    <motion.ul variants={ulVariant} animate="show">
                        <motion.li
                            variants={liVariant}
                            animate="show"
                            transition={{ duration: 0.2, delay: isOpen ? 0.1 : 0 }}>
                            <a className="text-decoration-none" onClick={() => handleItemClick(1)}>
                                <div>Nuestros platos</div>
                            </a>
                        </motion.li>
                        <motion.li
                            variants={liVariant}
                            animate="show"
                            transition={{ duration: 0.2, delay: isOpen ? 0.1 : 0 }}>
                            <a className="text-decoration-none" onClick={() => handleItemClick(2)}>
                                <div>Vinos de la casa</div>
                            </a>
                        </motion.li>
                    </motion.ul>
                </nav>
            </div>
        </header>
    );
};

export default HeaderLayoutCarta;
