import React from "react";
import PropTypes from "prop-types";
import RatingStar from "./RatingStar";


const InfoPlace = (props) => {
    const reviews = props.reviews;
    const placeInfo = props.placeInfo;

    //formatear hora de weeks

    const fechaFormateadas = placeInfo.weekday_text.map((day,index)=> {
        const horaDia = day.split(' ')
        const horas = horaDia[1].split('–');

        const horasFormateadas =  horas.map((hora,index)=>{

            if (horas.length > 1){
                const partes = hora.split(':')
                // Asegura que las partes tengan dos dígitos
                const hh = partes[0].padStart(2, '0');
                const mm = partes[1].padStart(2, '0');

                return hh+':'+mm;
            }
            return horaDia[1]
        })

        if (horasFormateadas.length >1){
            return horaDia[0] +' '+horasFormateadas[0]+'-'+horasFormateadas[1];
        }
        return horaDia[0] +' '+horasFormateadas[0];
     })



    return(
        <div className="info-place title-text">
            <div className="rating-general">
                <h2 className="text-big text-negroverdoso2">{placeInfo.name}</h2>
                <p className="">{placeInfo.formatted_address}</p>
                <RatingStar numStars={placeInfo.rating}/>
                <span className="text-medium">{placeInfo.rating}</span>
            </div>


            <div>

            </div>
            <div className="horario">
                <h2 className="text-small2 text-negroverdoso2">Horario</h2>
                <div className="container-text-horario">
                    {
                        fechaFormateadas.map((day,index) => (

                            <div key={index} className="text-horario">
                                <span className="">{day.split(':')[0]}</span>
                                <span className="">{day.split(' ')[1].replace('24','00')}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="divtituloresenas">
                <h2 className="text-small2 text-negroverdoso2">Últimas reseñas</h2>
                {/* Pendiente para el futuro
                <motion.button onClick={() => changePage(props.buttonurl)} className=" font-format-Gochi " whileHover={{ backgroundColor: "white" }} transition={{ type: "spring", stiffness: 100, damping: 5 }}>*/}
                {/*    Dános tu opinión!*/}
                {/*</motion.button>*/}
            </div>
            <div className="reviews">
                {
                    reviews.map((review,index)=>(

                        <React.Fragment>
                            <div key={index}>
                                <div className="cabecera-review title-text">
                                    <div className="user-details">
                                        <img className="photo-user" src={review.profile_photo_url} alt="photo user" key={index}/>
                                        <div className="user-desc">
                                            <span className="span-review text-bold-1">{review.author_name}</span>
                                            <span className="span-review text-gray">{review.relative_time_description}</span>
                                        </div>
                                    </div>
                                    <div className="rating">
                                        <RatingStar numStars={review.rating}/>
                                    </div>
                                </div>
                                <p className="text-description-reviews text-commentGoogle">{review.text}</p>
                            </div>
                        </React.Fragment>
                    ))

                }

            </div>
        </div>
    )
}
InfoPlace.propTypes = {
    reviews: PropTypes.array.isRequired,
    placeInfo:PropTypes.object.isRequired
}

export default InfoPlace;



