import {useEffect, useState} from "react";
import { motion } from 'framer-motion';
import './carruselEstatico.scss'
import animateAutoSecuence from "./Animate/animateAutoSecuence";
const CarruselAuto = (props) =>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [directionNormal, setDirectionNormal] = useState(true);
    const interval = props.interval? props.interval:3000
    const longitudImages = props.images.length
    const carrusel = {
        visibleItem: {
            x: `-${(currentIndex *100)}%`
        }
    };
    useEffect(() => {

        const intervalId = setInterval(() => {
            animateAutoSecuence(currentIndex,directionNormal,setCurrentIndex,setDirectionNormal,longitudImages);
        }, interval);

        return () => clearInterval(intervalId);
    }, [directionNormal,currentIndex]);
    return(
        <div className="carousel-estatico">
            <motion.div className="carusel-images-estatico"
                        variants={carrusel}
                        animate="visibleItem"
                        transition={{ type: "spring", stiffness: 20, damping: 10 }}
            >
                {props.images.map((image, index) => (
                    <div className="carousel-item">
                        <img
                            key={index}
                            src={require(`../../media/${image}`)}
                            alt={`Slide ${index}`}
                        />
                    </div>
                ))}
            </motion.div>
        </div>
    )
}
export default CarruselAuto;