import './styles/components/sassStyles'
import Main from "./components/main/Home/Main";
import React from "react";
import Footer from "./components/footer/Footer";
import {Route, Routes} from "react-router-dom";
import LinksToTop from "./components/main/general/LinksToTop";
import GestorHeader from "./components/header/GestorHeader";
import RestaurantRoutes from "./Routes/RestaurantRoutes";
import {HOME} from "./utils/Constants/NameConstants";
import Privacy from "./components/main/PoliticaDePrivacidad/Privacy";
import CookiesBanner from "./components/CookiesComponents/CookiesBanner";



function App() {
  return (
      <React.Fragment>
            <GestorHeader/>
                <Routes>
                    <Route path="/" element={<Main name={HOME}/>} />
                    <Route path="/restaurant/:code/*" element={<RestaurantRoutes/>} />
                    <Route path="/privacy" element={<Privacy/>}/>
                    {/*<Route path="/contact" element={<ContactContainer data="home"/>} />*/}
                    {/*<Route path="/job" element={<WorkWUContainer data="home"/>} />*/}
                    <Route path="*" element={<div>404</div>}/>
                </Routes>
                <LinksToTop/>
                <Footer/>
          <CookiesBanner/>
      </React.Fragment>
  );
}

export default App;
