import React, {createContext, useState} from 'react';

// Creamos un nuevo contexto
export const CookiesContext = createContext();

// Creamos un componente Provider que contendrá el estado que queremos compartir
const CartaProvider = ({ children }) => {
    const [cookies, setCookies] = useState(null);

    return (
        <CookiesContext.Provider value={{ cookies, setCookies }}>
            {children}
        </CookiesContext.Provider>
    );
};

export default CartaProvider;