import PropTypes from "prop-types";
import React from "react";
import estrellaVacia from '../../../media/star_review_blank.svg'
import estrellaNormal from '../../../media/star_review.svg'
import estrellaMitad from '../../../media/star_half.svg'


const RatingStar = (props) =>{
    const cantidadEstrellas = props.numStars;
    const cantidadString = cantidadEstrellas.toString();
    const decimal = cantidadString.split('.')[1];
    const arrayStars = []
    for (let i= 1.00 ; i <= 5; i++){
        if (i<=cantidadEstrellas){
            arrayStars.push(estrellaNormal);
        }else{
            if (decimal>=5 && (i-0.5)<= cantidadEstrellas){
                arrayStars.push(estrellaMitad);
            }else {
                arrayStars.push(estrellaVacia);
            }

        }
    }


    return(
        <React.Fragment>
            {
                arrayStars.map((estrella, index) => (
                    <img key={index} src={estrella} alt="Rating Start" />
                ))
            }
        </React.Fragment>
    )

}

RatingStar.propTypes ={
    numStars: PropTypes.number.isRequired
}
export default RatingStar;