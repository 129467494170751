import CarruselDragAuto from "../../../Carrusell/CarruselDragAuto";
import "./cartaSectionStyles.scss"
import {motion} from "framer-motion";
import React, {useContext} from "react";
import {CartaContext} from "../../../../providers/CartaProvider";
import {useNavigate} from "react-router-dom";
const CartaSection = (props) => {
    const navigate = useNavigate();
    const { selectCarta, setSelectCarta } = useContext(CartaContext);
    const titleTextVariant = {
        hidden: { opacity: 0},
        show: { opacity: 1 ,transition: {duration: 1}},
    }
    const redirigirACarta = (e) => {
        setSelectCarta(1)
        navigate(props.buttonurl);
    }

    return(
        <section className="sectionCarta-Restaurant">
            <div className="divCarrussel-sectionCartaRestaurant">
                <CarruselDragAuto images={props.imagesCarrusel}/>
            </div>
            <motion.div className="divText-sectionCartaRestaurant" variants={titleTextVariant} initial="hidden" whileInView="show" viewport={{ once:true,amount: 0.6}}>
                <h2 className="font-format-Gochi title-section text-negroverdoso">{props.title}</h2>
                <p className="font-format-Gochi text-small2 text-negroverdoso">
                    {props.description}
                </p>
                <div className="button-container">

                 <motion.button onClick={redirigirACarta}
                                   className="buttonCarta text-medium font-format-Gochi"
                                   whileHover={{ scale: 1.2 }}
                                   transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                        CARTA</motion.button>
                </div>
            </motion.div>
        </section>
    )
}
export default CartaSection;