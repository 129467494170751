import React from "react";
import {Route, Routes, useParams} from "react-router-dom";
import MainRestaurant from "../components/main/MainRestaurants/MainRestaurant";
import ContactContainer from "../components/Forms/contacta/ContactContainer";
import LayoutMenu from "../components/main/Menu/LayoutMenu/LayoutMenu";
import CartaMain from "../components/main/Carta/CartaLayout/CartaMain";
import WorkWUContainer from "../components/Forms/workWithUs/WorkWUContainer";
import GestorMenu from "../components/main/Menu/LayoutMenu/GestorMenu";
const RestaurantRoutes = () => {
    const { code } = useParams();

    return (
        <Routes>
            <Route index={true} element={<MainRestaurant code={code} />} />
            <Route path="contact" element={<ContactContainer code={code} />} />
            <Route path="menu" element={<GestorMenu code={code}/>} />
            <Route path="menuEspeciales" element={<GestorMenu code={code} especial={true}/>} />
            <Route path="carta" element={<CartaMain  code={code}/>} />
            <Route path="job" element={<WorkWUContainer  code={code}/>} />
            <Route path="*" element={<div>404</div>}/>
        </Routes>
    );
}
export default RestaurantRoutes;