import {optionsGet} from "../optionsMethods";
import {urlbackendservicios} from "../../../conf";
import {useEffect, useState} from "react";

const useReviews = (placeId, sortingMethod) => {
    const [dataReviews,setDataReviews] = useState(null);
    // ruta de la petición
    const path = `${urlbackendservicios}servicios/reviews/getAll?placeId=${placeId}&sortingMethod=${sortingMethod}`;

    const fetchGetReviews = async () => {
        try {
            const response = await fetch(path,optionsGet);
            if (!response.ok) {
                throw new Error(`Error al obtener datos : ${response.statusText}`);
            }

            const jsonData = await response.json();
            setDataReviews(jsonData);

        } catch (error) {
            console.error('Error al obtener datos JSON:', error);
        }
    }
    useEffect(()=>{
        fetchGetReviews();
    },[])

    return {dataReviews}
}
export default useReviews;