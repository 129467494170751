import './menu.scss';
import HeaderLayoutMenu from "./HeaderLayoutMenu";
import { useEffect, useState } from "react";
import SecctionMenu from "./SecctionMenu";
import React from "react";
import LoadingScreen from "../../../LoadingScreens/LoadingScreen";
import useMenuProductos from "../../../../utils/Apis/productosApi/useMenuProductos";
import sortMenu from "../../../../utils/sorts/sortMenu";
import LoadingScreenSection from "../../../LoadingScreens/LoadingScreenSection";
import PropTypes from "prop-types";
import { motion } from 'framer-motion';
import {Helmet} from "react-helmet-async";
import initToogle from "./initToogle";
import {prettyDOM} from "@testing-library/react";

const LayoutMenu = (props) => {
    const code = props.code;
    const especial = props.especial!==undefined? props.especial: false; // es opcional
    const data = props.data;
    const [loading, setLoading] = useState(true);
    const {initOption} = initToogle(props.menus,especial);
    const [toggleOption, setToggleOption] = useState(initOption);
    const [labelData,setLabelData]= useState(null);
    const {dataMenuProductos} = useMenuProductos(data.conf.establecimiento, toggleOption.value);


    useEffect(() => {
        if (dataMenuProductos) {
            const uniqueLabels = [...new Set(dataMenuProductos.map(item => item.ordenProducto))];
            uniqueLabels.sort(sortMenu);
            setLabelData(uniqueLabels);
        }
    }, [dataMenuProductos]);


    if (loading) {
        return <LoadingScreen call={setLoading} />;
    }

    return (
        <motion.div className="containerMainMenu"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.4}}>

            <Helmet>
                <link rel="canonical" href={"https://www.grupocasapijo.es/restaurant/" + code + (especial ? "menuEspeciales" : "menu")}/>
                <title>{data.helmets.helmetMenus.titulo}</title>
                <meta name="description" content= {data.helmets.helmetMenus.descripcion}/>
                <meta property="og:title" content= {data.helmets.helmetMenus.titulo}/>
                <meta property="og:description" content= {data.helmets.helmetMenus.descripcion}/>
                <meta name="keywords" content= {data.helmets.helmetMenus.keywords}/>
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="layoutMenu">
                <HeaderLayoutMenu nameMenu={toggleOption.nombre} setToggleOption={setToggleOption} menus={props.menus} dataHeader={data} />
                <main>
                    {dataMenuProductos && labelData ? (
                        labelData.map((label, index) => (
                            <section key={index}>
                                <h2 className="text-big2 text-center titulo-sectionCarta font-format-Gochi">
                                    {label==='primero' && especial? 'Entrante':label}
                                    {especial&&label!=='bebida'? ((toggleOption.value === 8 && label === 'segundo') ? ' ELEGIR POR PAX' :' A COMPARTIR 4 PAX') :(especial)?' (CONSUMICIONES POR PAX)':''}
                                </h2>
                                <SecctionMenu productos={dataMenuProductos} label={label} especial={especial}/>
                            </section>
                        ))
                    ) : (
                        <LoadingScreenSection />
                    )}
                    <section>
                         <pre className="pre-menu-price">
                        {
                            especial?
                                 "Precio total del Menú por persona "+props.menus.get(toggleOption.value).precio+ "€ (IVA incluido)"
                                :

                                "PVP "+props.menus.get(toggleOption.value).precio+ "€ (IVA incluido) \n\n Menú 1 plato con bebida, pan y postre ó café 11€ (IVA incluido)"
                        }
                        </pre>
                    </section>
                </main>
            </div>
        </motion.div>
    );
};

LayoutMenu.propTypes = {
    data: PropTypes.object.isRequired,
    menus: PropTypes.any.isRequired,
    especial: PropTypes.bool
}
export default LayoutMenu;
