import React, {useContext, useEffect, useRef, useState} from "react";
import './sectionMapStyles.scss'
import InfoPlace from "./InfoPlace";
import PropTypes from "prop-types";
import useReviews from "../../../utils/Apis/google/useReviews";
import usePlaceInfo from "../../../utils/Apis/google/usePlaceInfo";
import goToAnchorTimeOut from "../../../utils/jsonUtils/goToAnchorTimeOut";
import LoadingScreenSection from "../../LoadingScreens/LoadingScreenSection";
import GoogleMapsEmbed from "./Map/GoogleMapsEmbed";
import {CookiesContext} from "../../../providers/CookiesProvider";
import SectionCookies from "../../CookiesComponents/SectionCookies";
const SectionMainGoogle = (props) => {
    //constantes
    const idEstablecimiento = props.id_establecimiento;
    const order = "newest"
    const {dataReviews} =  useReviews(idEstablecimiento, order);
    const {dataPlaceInfo} =  usePlaceInfo(idEstablecimiento);
    const {cookies} = useContext(CookiesContext)
    //estados
    // const [openIndex, setOpenIndex] = useState(null); esto es para el uso de google Maps Java Script, ahora funciona con el embed
    const [loading, setLoading] = useState(true);//pasar al futuro loading
    const refGoogleSection = useRef(null);
    const refTimeout = useRef(null);
    //acciones - para google Maps Java Script por el momento solo utilizamos el embed
    // const handleOpenIndexChange = (index) => {
    //     if (index === openIndex){
    //         setOpenIndex(null)
    //         setTimeout(()=>setOpenIndex(index),10)
    //     }else {
    //
    //         setOpenIndex(index);
    //     }
    // };


    //obtener reviews, (fotos? y info place)
    useEffect(  ()=>{

        //función creado para ir a la ancla durante el cambio de la página recibe la referencia del elemento o la ancla
        refTimeout.current =  goToAnchorTimeOut(refGoogleSection.current); // te devuelve el identificador del timeOut para que puedas destruir si el componente se destruye
        return () => {
            clearTimeout(refTimeout.current)
        }
    },[])

    return(
        <section ref={refGoogleSection} id="seccionGoogle" className="section-map-google">
            {
                (!cookies)?
                <SectionCookies nameSection="Google Maps"/>
                :
                (dataReviews&&dataPlaceInfo)?
                <React.Fragment>
                <div className="div-mapa">
                <GoogleMapsEmbed dataMap={props.dataMap.map}/>
                {/*<GoogleMaps data={props.dataMap.map} openIndex={openIndex} setOpenIndex={handleOpenIndexChange}/>*/}
                </div>
                    <div className="description-place ">
                        <InfoPlace reviews={dataReviews} placeInfo={dataPlaceInfo}/>
                    </div>
                </React.Fragment>
                :
                    <LoadingScreenSection/>
            }
        </section>


    );
}

SectionMainGoogle.propTypes = {
    dataMap: PropTypes.object.isRequired,
    id_establecimiento: PropTypes.number.isRequired
}
export default SectionMainGoogle;