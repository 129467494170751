
const LinkSocial = (props) => {
    return (
        <a href={props.urlLink} className="text-decoration-none">
            <div className="row">
                <div className="col text-center"><img src={props.imgsSrc} alt="likedin"/></div>
            </div>
            <div className="row">
                <div className="col font-normal text-center">{props.linkText}</div>
            </div>
        </a>
    );
}
export default LinkSocial;