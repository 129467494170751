import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import gifSidra from '../../media/sidra2.gif';
import './LoadingScreen.css';

const LoadingScreen = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            props.call(false);
        }, 1500);

        const timer2 = setTimeout(() => {
            setIsLoading(false);
        }, 1500);

        return () => {
            clearTimeout(timer);
            clearTimeout(timer2);
        };
    }, []);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.4 } },
        exit: { opacity: 0, transition: { duration: 0.4 } }
    };

    const gifVariants = {
        initial: { opacity: 1 },
        animate: {
            opacity: [0, 1, 0],
            transition: {
                duration: 3,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "mirror"
            }
        }
    };

    return (
        <motion.div
            className="loading-screen"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <div className="gif-container">
                <motion.img
                    src={gifSidra}
                    className="loader-gif"
                    alt="Cargando..."
                    variants={gifVariants}
                    initial="initial"
                    animate="animate"
                />
            </div>
        </motion.div>
    );
};

export default LoadingScreen;
