import '../header.scss'
import PropTypes from "prop-types";
import DropDown from "../../Toggles/DropDown";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LogoHeader from "../LogoHeader";
const HeaderMovil = (props) => {
    const [options, setOptions] = useState([]);
    const navegation = useNavigate();
    useEffect(()=>{
        let optionsData = [];
        if (props.dataHeader){
            props.dataHeader.header.links.map((link,index) =>{
                let objectHeader = {
                    text:link.name,
                    value:link.url
                }
                optionsData.push(objectHeader);
            })
            setOptions(optionsData);
        }
    },[props.dataHeader])

    const handdleRedirect = (e) => {//acción del toggle
        const url =  e.target.getAttribute('value');
        //contruimos el path y hash
        const urlForObjet = url.split('#');

        const objNavigate = {
            pathname:urlForObjet[0],
            hash:(urlForObjet[1]?('#'+urlForObjet[1]):'')
        }
        if (objNavigate.pathname !== window.location.pathname){
            navegation(objNavigate);
            return
        }
        const hashView = document.getElementById(urlForObjet[1]);//obtenemos el div o section con la ancla sin el #
        if (hashView){
            hashView.scrollIntoView();//visualizamos el ancla
        }
    }
    return(
      <div className="header-movil">
          {props.dataHeader?<LogoHeader img={props.dataHeader.header.headerImage} isMovil={true}/>:''}
          <div className="toggle-header-main">
              <DropDown options={options}
                        action={handdleRedirect}
                        colorBackgroundButton="transparent"
                        colorBackgroundToggle="transparent"
                        colorToggle="white"
                        classNameUl="toogle-header-ul-movil"
                        classNameLi="toogle-header-li-movil"
              />
          </div>
      </div>
    );

}

HeaderMovil.propTypes = {
    dataHeader : PropTypes.object.isRequired
}
export default HeaderMovil;