import React from "react";
import '../cartaLayout.scss'

const ElementoCartaVinos = (props) => {

    return (
        <React.Fragment>
            {
                props.dataVinos.map((element,index)=>(
                    (props.keyIndex === element.tipo && (element.precioCopa || element.precioBotella)) ?
                        <div key={index} className="contenedorElemento-Carta">
                            <div>
                                <h3 className="text-small2 margenestitulos">{element.nombre}</h3>
                                <p className="contenedor-precios">
                                    {element.precioCopa && (
                                        <div className="precioCopa">
                                            <div className="svgCopa">
                                            </div>
                                            <div className="infoPrecio">
                                                {element.precioCopa}€
                                            </div>
                                        </div>
                                    )}
                                    {element.precioBotella && (
                                        <div className="precioBotella">
                                            <div className="svgBotella">
                                            </div>
                                            <div className="infoPrecio">
                                                {element.precioBotella}€
                                            </div>
                                        </div>
                                    )}
                                </p>
                            </div>
                            <p className="text-small">
                                {element.alergenos}
                            </p>
                            <hr/>
                        </div>
                        :
                        ''

                ))
            }
        </React.Fragment>
    );
}

export default ElementoCartaVinos;
