import {urlbackend} from "../../../conf";
import {optionsPost} from "../optionsMethods";
import {useEffect, useState} from "react";

const useMenuProductos = (idEstablecimiento, idMenu) => {
    const [dataMenuProductos,setDataMenuProductos] = useState(null);
    // ruta de la petición
    const path = urlbackend+"productos/menu/getProdMenu";
    const  request = {
        id_establecimiento: idEstablecimiento,
        id_menu: idMenu,
        enable: true
    };
    // Opciones para la solicitud
    optionsPost.body = JSON.stringify(request);

    const fetchGetMenuProductos = async () => {
        try {
            const response = await fetch(path,optionsPost);
            if (!response.ok) {
                throw new Error(`Error al obtener datos : ${response.statusText}`);
            }

            const jsonData = await response.json();
            setDataMenuProductos(jsonData);

        } catch (error) {
            console.error('Error al obtener datos JSON:', error);
        }
    }

    useEffect(()=>{
        fetchGetMenuProductos();
    },[idMenu])

    return {dataMenuProductos}
}
export default useMenuProductos;