const getLocalStorage = (clave) => {
    const valor = localStorage.getItem(clave);
    if (valor === null) {
        // La clave no existe en localStorage
        return null;
    }
    try {
        // Intentar convertir el valor a un objeto
        return JSON.parse(valor);
    } catch (e) {
        // Si falla, entonces es una cadena
        return valor;
    }

}
export default getLocalStorage;