import React, {useState} from "react";
import ContactText from "./ContactText";
import ContactForm from "./ContactForm";
import { useMediaQuery } from 'react-responsive';

import './contact.scss'
import LoadingScreen from "../../LoadingScreens/LoadingScreen";
import getJson from "../../../utils/jsonUtils/getJson";
import PropTypes from "prop-types";
import { motion } from 'framer-motion';
import {Helmet} from "react-helmet-async";
const ContactContainer = (props) => {
    //Obtenemos la variable de la url para comprobar si el contacto se encuentra en la home o en alguna de las páginas de los restaurantes
    const code = props.code;
    const data = getJson(code);
    const [loading , setLoading] = useState(true);
    const isDesktop = useMediaQuery({ minWidth: 851 });
    const isTabletorMovile = useMediaQuery({ maxWidth: 850 });


    return(
        <React.Fragment>
            {(data &&
                <Helmet>
                    <link rel="canonical" href={"https://www.grupocasapijo.es/restaurant/" + code + "contact/"}/>
                    <title>{data.helmets.helmetcontactForm.titulo}</title>
                    <meta name="description" content= {data.helmets.helmetcontactForm.descripcion}/>
                    <meta property="og:title" content= {data.helmets.helmetcontactForm.titulo}/>
                    <meta property="og:description" content= {data.helmets.helmetcontactForm.descripcion}/>
                    <meta name="keywords" content= {data.helmets.helmetcontactForm.keywords}/>
                    <meta property="og:type" content="website" />
                </Helmet>
            )}
            {
                !loading&&data?
                    <React.Fragment>

                        {isDesktop && <DesktopComponent data={data} />}
                        {isTabletorMovile && <TabletOrMobileComponent data={data} />}
                    </React.Fragment>
                    :
                    <LoadingScreen call={setLoading}/>
            }
        </React.Fragment>

    )
}

const DesktopComponent = ({ data }) =>  <motion.div className="divgeneral"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.4}}>
                                            <div className="divformandtext">
                                                <ContactText cont={data.contact}></ContactText>
                                                <ContactForm rutaHome = {data.header.headerImage.url} idRest = {data.conf.establecimiento}></ContactForm>
                                            </div>
                                        </motion.div>

const TabletOrMobileComponent = ({ data }) => <motion.div className="divgeneral"
                                                          initial={{ opacity: 0 }}
                                                          animate={{ opacity: 1 }}
                                                          transition={{ duration: 0.4}}>
                                        <div className="divformandtext">
                                            <ContactText cont={data.contact}></ContactText>    
                                        </div>
                                        <div className="divformandtext">
                                            <ContactForm idRest = {data.conf.establecimiento}></ContactForm>
                                        </div>
                                     </motion.div>


ContactContainer.propTypes = {
    code: PropTypes.string
}
export default ContactContainer;