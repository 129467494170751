const getDataForms = (formData) => {
    // Inicializar un array para almacenar los datos
    const formDataArray = [];

    // Iterar por cada campo de entrada (input)
    for (let i = 0; i < formData.elements.length; i++) {
        const element = formData.elements[i];
        if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
            formDataArray.push(element.value);
        }
    }

    return formDataArray
}
export default getDataForms;