import PropTypes from "prop-types";
import LayoutMenu from "./LayoutMenu";
import getJson from "../../../../utils/jsonUtils/getJson";
import useMenuAll from "../../../../utils/Apis/productosApi/useMenuAll";

const GestorMenu = (props) => {
    const code = props.code;
    var res = null;
    const especial = props.especial!==undefined? props.especial: false; //es opcional
    const data = getJson(code);
    const {dataMenu} = useMenuAll(data.conf.establecimiento,especial);



    if (data&&dataMenu){
        if (especial){
            res = <LayoutMenu code = {code} data={data} menus={dataMenu} especial={true}/>;
        }else{
            res = <LayoutMenu code = {code}  data={data} menus={dataMenu}/>;
        }
    }

    return res;

}

GestorMenu.propTypes = {
    code: PropTypes.string.isRequired,
    especial: PropTypes.bool
}

export default GestorMenu;