import {useEffect, useRef, useState} from "react";
import { motion } from 'framer-motion';
import './carruselEstatico.scss'
import animateAutoSecuence from "./Animate/animateAutoSecuence";
const CarruselDragAuto = (props) =>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [directionNormal, setDirectionNormal] = useState(true);
    const containerCarrusel = useRef(null);
    const timeOut = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [drag, setDrag] = useState(true);
    const interval = props.interval? props.interval:3000
    const longitudImages = props.images.length
    const carrusel = {
        visibleItem: {
            x: drag? '' :`-${(currentIndex *100)}%`
        }
    };
    const handleDragging = (e) => {
        clearTimeout(timeOut.current)
        setDrag(true)
    };
    const handleDragginOut = (e) =>{
        const time = setTimeout(()=>{
            setDrag(false)
        },2000)
        timeOut.current=time;
    };
    useEffect(() => {
        if (containerCarrusel.current){
            setContainerWidth(containerCarrusel.current.clientWidth)
        }
        const intervalId = setInterval(() => {
            animateAutoSecuence(currentIndex,directionNormal,setCurrentIndex,setDirectionNormal,longitudImages);
        }, interval);

        return () => clearInterval(intervalId);
    }, [directionNormal,currentIndex,containerWidth]);
    return(
        <div ref={containerCarrusel} className="carousel-estatico pointer-carrusel">
            <motion.div className="carusel-images-estatico"
                        variants={carrusel}
                        drag="x"
                        draggable="true"
                        dragConstraints={{ right: 0, left: -(containerWidth*(longitudImages-1)) }}
                        animate="visibleItem"
                        onDragStart={handleDragging}
                        onDragEnd={handleDragginOut}
                        onLoad={handleDragginOut}
                        transition={{ type: "spring", stiffness: 40, damping: 10 }}
            >
                {props.images.map((image, index) => (
                    <motion.div>
                        <img
                            key={index}
                            src={require(`../../media/${image}`)}
                            alt={`Slide ${index}`}
                        />
                    </motion.div>
                ))}
            </motion.div>
        </div>
    )
}
export default CarruselDragAuto;