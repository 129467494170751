import LogoHeader from "../LogoHeader";
import OptionsHeader from "./OptionsHeader";
import { useEffect, useRef, useState } from "react";
import {useLocation} from "react-router-dom";
import React from "react";
import '../header.scss'
import {motion} from "framer-motion";
import PropTypes from "prop-types";

const Header = (props) => {
    const headerRef = useRef(null);
    const data = props.dataHeader;
    const currentPath = props.currentPath;
    const location = useLocation();
    const [showBackground, setShowBackground] = useState(false);
    const [fixed, setFixed] = useState(true);
    var loadingDelay= 1;

    useEffect(() => {
        const isCarta = currentPath.includes('carta');
        const isMenu = currentPath.includes('menu');
        if (isCarta){
            setFixed(false)
        }else {
            setFixed(true)
            loadingDelay = 1.5;
        }
        const handleScroll = () => {//función de evento
            if (window.scrollY > 50 && !isCarta && !isMenu) { // Adjust 50 to where you want the animation to start
                setShowBackground(true);
            } else {

                setShowBackground(false);
            }
        };
        //se añade el event
        window.addEventListener('scroll', handleScroll);

        return () => {//cuando se desmonta el componente
            window.removeEventListener('scroll', handleScroll);
        };

    }, [showBackground,fixed,location]);
    //aqui termina el useEffect para fijarla

    return(

        <motion.div id="header" ref={headerRef} className={`header ${showBackground ? 'show-bg' : ''} ${fixed? 'staticHeader':'' }`}
                    initial={{ opacity: 0, y: -180 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        // ease: "easeInOut",
                        duration: 1.5,
                        delay: 5,
                    }}>
            {
                data?
                    <React.Fragment>
                        <LogoHeader img={data.header.headerImage} />
                        <OptionsHeader links={data.header.links}/>
                    </React.Fragment>
                    :
                    <React.Fragment/>
            }
        </motion.div>
    );
}

Header.propTypes = {
    dataHeader: PropTypes.object.isRequired,
    currentPath: PropTypes.string.isRequired
}

export default Header;