const getListasPrivacy = (n,lista) => {
    if (!lista) return null;
    let listItems = lista.elements.map((element,index) => {
        if (n === 0) {
            return (
                <li type="A" key={index}>
                    <h3 className="title-text">{element.subtitulo}</h3>
                    {element.descripcion?<p className="title-text">{element.descripcion}</p>:''}
                    {getListasPrivacy(n + 1, (element.lista) ? element.lista : false)}
                    {element.descripcionEnd?<p className="title-text">{element.descripcionEnd}</p>:''}
                </li>
            );
        } else {
            return (
                <li className="list-ul-privacy" key={index}>{element.subtitulo}</li>
            );
        }
    });
    return lista.type==='ol'? <ol key={n}>{listItems}</ol>:<ul key={n}>{listItems}</ul> ;
}
export default getListasPrivacy;