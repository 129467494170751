import "./menuStyles.scss"
import {changePage} from "../../../../utils/jsonUtils/functionsButtons";
import {motion} from "framer-motion";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CartaContext} from "../../../../providers/CartaProvider";

const MenuSection = (props) => {
    const movilSize = 1050;
    const { selectCarta, setSelectCarta } = useContext(CartaContext);
    const navegation = useNavigate();
    const [size, setSize] = useState(window.innerWidth);
    const cardTextVariant = {
        hidden: { opacity: 0, x:-100},
        show: { opacity: 1 ,transition:{duration: 1, type: "spring", stiffness: 100, damping: 10}, x:0}
    }
    const cardImageVariant = {
        hidden: { opacity: 0, x:35},
        show: { opacity: 1 ,transition: {duration: 1, type: "spring", stiffness: 100, damping: 10}, x:0},
    }
    const titleTextVariant = {
        hidden: { opacity: 0},
        show: { opacity: 1 ,transition: {duration: 1}}
    }

    const handleResize = (e) => {
        setSize(window.innerWidth)
    }
    const buttonAction = (url) => {
        setSelectCarta(2);
        navegation(url);
    }
    window.addEventListener('resize',handleResize)
    return(
        <section className="sectionMenu">
            <motion.div className="divTitle" variants={titleTextVariant} initial="hidden" whileInView="show" viewport={{once: true, amount: 1}}>
                <h2 className="font-format-Gochi title-section text-white">{props.menuInfo.title}</h2>

            </motion.div>
            <div className="divCards">
                <motion.div className="divTextCard" variants={cardTextVariant} initial = "hidden" whileInView="show" viewport={{once: true}} whileHover={{ scale: 1.07}}
                            whileTap={{ scale: 1 }}
                            drag={size>movilSize}
                            dragConstraints={{ left: 0, right: 0, top: 0, bottom:0}}>
                    <h3 className="font-format-Gochi text-medium text-negroverdoso">{props.menuInfo.titleleft}</h3>
                    <p className="font-format-Gochi text-small2 text-negroverdoso">{props.menuInfo.pleft}</p>
                    <ul>
                        <li className="font-format-Gochi text-small2">{props.menuInfo.frase1left}</li>
                        <li className="font-format-Gochi text-small2">{props.menuInfo.frase2left}</li>
                        <li className="font-format-Gochi text-small2">{props.menuInfo.frase3left}</li>
                    </ul>
                    <div className="divButton" >
                        <motion.button onClick={() => buttonAction(props.menuInfo.ButtonLeft.urlButton)}
                                       className="buttonMenu font-format-Gochi"
                                       whileHover={{ scale: 1.05 }}
                                       transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                            {props.menuInfo.ButtonLeft.textButton}
                        </motion.button>
                    </div>
                </motion.div>
                <motion.div className="divTextCard" variants={cardTextVariant} initial = "hidden" whileInView="show" viewport={{once: true}} whileHover={{ scale: 1.07}}
                            whileTap={{ scale: 1 }}
                            drag={size>movilSize}
                            dragConstraints={{ left: 0, right: 0, top: 0, bottom:0}}>
                    <h3 className="font-format-Gochi text-medium text-negroverdoso">{props.menuInfo.titleright}</h3>
                    <p className="font-format-Gochi text-small2 text-negroverdoso">{props.menuInfo.pright}</p>
                    <ul>
                        <li className="font-format-Gochi text-small2">{props.menuInfo.frase1right}</li>
                        <li className="font-format-Gochi text-small2">{props.menuInfo.frase2right}</li>
                        <li className="font-format-Gochi text-small2">{props.menuInfo.frase3right}</li>
                    </ul>
                    <div className="divButton" >
                        <motion.button onClick={() => buttonAction(props.menuInfo.ButtonRight.urlButton)}
                                       className="buttonMenu font-format-Gochi"
                                       whileHover={{ scale: 1.05 }}
                                       transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                            {props.menuInfo.ButtonRight.textButton}
                        </motion.button>
                    </div>
                </motion.div>
            </div>
            <div className="divImgCard">
                <img alt="" src={require(`../../../../media/${props.menuInfo.image}`)}/>
            </div>
        </section>
    )
}
export default MenuSection;