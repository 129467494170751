import {Link} from "react-router-dom";

const Links = (props) => {
    return (
        <div className="col-4 text-center ">
            <ul className="nav text-medium">
                {
                    props.data.map((link,index)=>(
                        <li key={index}><Link className="abel-regular text-small3" to={link.url}>{link.name}</Link></li>
                    ))
                }
            </ul>
        </div>
    );
}
export default Links;