import {urlbackendcomunicaciones} from "../../../conf";
const postJobApplication = async (id, nombre, apellidos, correo, movil, nacimiento, mensaje, file) => {
    id = 2;
  
    const path = urlbackendcomunicaciones + "forms/jobs";
    const formData = new FormData();
    formData.append("id_establecimiento", id);
    formData.append("nombre", nombre);
    formData.append("apellidos", apellidos);
    formData.append("correo_electronico", correo);
    formData.append("movil", movil);
    formData.append("fecha_nacimiento", nacimiento);
    formData.append("mensaje", mensaje);
    formData.append("cv", file); // append the file to the form data
  
    try {
      const response = await fetch(path, {
        method: "POST",
        body: formData // send the form data as the request body
      });
  
      if (!response.ok) {
        throw new Error(`Error al obtener datos : ${response.statusText}`);
      }
  
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error al obtener datos JSON:", error);
      return null;
    }
};

export default postJobApplication;