import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import './toggles.scss';
const DropDown = (props) => {//tiene que estar en un contenedor de relativo
    //vars optional
    const backgroundToggle = props.colorBackgroundToggle? props.colorBackgroundToggle: "#c4cbc4";
    const colorToggle = props.colorToggle? props.colorToggle: "#4b564c";
    const backgroundButton = props.colorBackgroundButton? props.colorBackgroundButton : "#c4cbc4";
    const classNameUl = props.classNameUl? props.classNameUl: "";
    const classNameLi = props.classNameLi? props.classNameLi: "";
    //animate toggle
    const [isOpen, setIsOpen] = useState(false);
    const ulVariant = {
        show:{
            clipPath: isOpen
                ? "inset(0% 0% 0% 0% round 10px)"
                : "inset(10% 50% 90% 50% round 10px)",
            transition:{
                type: "spring",
                bounce: 0,
                duration: 0.5,
            }
        }
    }

    const liVariant = {
        show: isOpen ? { opacity: 1, scale: 1, filter: "blur(0px)" }
            : { opacity: 0, scale: 0.3, filter: "blur(20px)" }}

    const toggleVariant ={
        animate:{ rotate: isOpen ? 180 : 0 ,
            originX: 0.5, // Ajustamos el origen de la transformación en el eje X
            originY: 0.5, // Ajustamos el origen de la transformación en el eje Y
            transition: {duration: 0.2}
        }
    }


    //función que ejecuta la función del botón y lo setea a false, si se encuentra abierto el toggle
    const accionButton = (e) => {
        setIsOpen(false);
        props.action(e);
    }
    useEffect(()=>{
        const handleResize = (e) => {
            setIsOpen(false);
        }

        //se añade el event
        window.addEventListener('resize', handleResize);

        return () => {
            //cuando se desmonta el componente
            window.removeEventListener('resize', handleResize);
        };
    },[])

    return(
        <nav className="toggle-drop-down">
            <motion.button whileTap={{ scale: 0.9 }}
                           onClick={() => setIsOpen(!isOpen)}
                           style={{backgroundColor:backgroundButton}}
            >
                <motion.div className="toggle-menu" variants={toggleVariant} animate="animate">
                    <svg width="30" height="30" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="20" fill={backgroundToggle}/>
                        <rect x="5" y="5" width="10" height="2" fill={colorToggle}/>
                        <rect x="5" y="9" width="10" height="2" fill={colorToggle}/>
                        <rect x="5" y="13" width="10" height="2" fill={colorToggle}/>
                    </svg>
                </motion.div>
            </motion.button>
            <motion.ul variants={ulVariant} animate="show" className={classNameUl}>
                {
                    props.options.map((option,index) => (
                        <motion.li key={index} variants={liVariant}
                                   animate="show"
                                   className={classNameLi}
                                   onClick={accionButton}
                                   value={option.value}
                                   transition={{duration:0.2, delay:isOpen?  (0.1) : 0}}
                        >
                            {option.text}
                        </motion.li>
                    ))
                }

            </motion.ul>
        </nav>
    );
}

DropDown.propTypes = {
    options: PropTypes.array.isRequired,
    action: PropTypes.func.isRequired,
    classNameUl: PropTypes.string,
    classNameLi: PropTypes.string,
    colorBackgroundButton: PropTypes.string,
    colorBackgroundToggle: PropTypes.string,
    colorToggle: PropTypes.string
}

export default DropDown;