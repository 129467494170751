import {useContext, useState} from "react";
import LoadingScreen from "../../LoadingScreens/LoadingScreen";
import React from "react";
import { motion } from 'framer-motion';
import DescriptionMainRest from "../Description/DescriptionMainRest";
import CartaSection from "../Carta/CartaSectionMain/CartaSection";
import SectionCarruselTop from "../sectionCarruselTop/SectionCarruselTop";
import getJson from "../../../utils/jsonUtils/getJson";
import MenuSection from "../Menu/MenuSectionMain/MenuSection";
import SectionMainGoogle from "../GoogleSection/SectionMainGoogle";
import {CartaContext} from "../../../providers/CartaProvider";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet-async";


const MainRestaurant = (props) =>{
    const code = props.code;
    const { selectCarta, setSelectCarta } = useContext(CartaContext);
    const data = getJson(code);
    const [loading, setLoading] = useState(true);


    // if (code === "lacachimba") {
    //     return(
    //         <PageNotReady/>
    //     )
    //
    // }

    return(
        <main>
            <Helmet>
                <link rel="canonical" href={"https://www.grupocasapijo.es/restaurant/" + code+"/"}/>
                <title>{data.helmets.helmetmainres.titulo}</title>
                <meta name="description" content= {data.helmets.helmetmainres.descripcion}/>
                <meta property="og:title" content= {data.helmets.helmetmainres.titulo}/>
                <meta property="og:description" content= {data.helmets.helmetmainres.descripcion}/>
                <meta name="keywords" content= {data.helmets.helmetmainres.keywords}/>
                <meta property="og:type" content="website" />
            </Helmet>
            {

                (!loading)?
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.5}}>
                        <SectionCarruselTop images={data.main.sectionCarrusel.images}
                                            imageCenter={data.main.sectionCarrusel.imageCenter}/>

                        <DescriptionMainRest
                            title= {data.main.sectionDescription.title}
                            description= {data.main.sectionDescription.description}
                            buttonurl={data.main.sectionDescription.urlButton}/>

                        <CartaSection
                            imagesCarrusel = {data.main.sectionCarta.images}
                            buttonurl = {data.main.sectionCarta.Button.url}
                            description= {data.main.sectionCarta.description}
                            title = {data.main.sectionCarta.title}
                        />

                        {/*{code == "lacachimba" && <CartaVinos*/}
                        {/*    imagesCarrusel = {data.main.sectionCarta.images}*/}
                        {/*    buttonurl = {data.main.sectionCarta.Button.url}*/}
                        {/*    description= {data.main.sectionCarta.description}*/}
                        {/*    title = {data.main.sectionCarta.title}*/}
                        {/*    selectCarta = {selectCarta}*/}
                        {/*    setSelectCarta = {setSelectCarta}*/}
                        {/*/>}*/}
                        <MenuSection menuInfo = {data.main.DivMenu}/>

                        <SectionMainGoogle dataMap = {data.main.DivMap} id_establecimiento={data.conf.establecimiento}/>
                    </motion.div>


                    :
                    <LoadingScreen call={setLoading}/>
            }


        </main>
    );
}
MainRestaurant.propTypes = {
    code: PropTypes.string
}
export default MainRestaurant;