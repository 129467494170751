import CarruselAuto from "../../Carrusell/CarruselAuto";
import React from "react";
import './sectionCarruselTop.scss';

const SectionCarruselTop = (props) => {
    return (
        <section className="section-carrusel-top">
            <div className="carousel-container">
                <CarruselAuto images={props.images} interval={5000} />
            </div>
            {/*<div className="image-div">*/}
            {/*    <img alt="title Page" src={require(`../../../media/${props.imageCenter}`)} />*/}
            {/*</div>*/}
        </section>
    );
};

export default SectionCarruselTop;
