import React, {createContext, useState} from 'react';

// Creamos un nuevo contexto
export const CartaContext = createContext();

// Creamos un componente Provider que contendrá el estado que queremos compartir
const CartaProvider = ({ children }) => {
    const [selectCarta, setSelectCarta] = useState(1);

    return (
        <CartaContext.Provider value={{ selectCarta, setSelectCarta }}>
            {children}
        </CartaContext.Provider>
    );
};

export default CartaProvider;