import {useEffect} from 'react'
import {urlbackend} from "../../../conf";
import {optionsPost} from "../optionsMethods";
import {useState} from "react";


const useCarta = (id,enable) =>{
    // ruta de la petición
    const path = urlbackend+"productos/carta/cartaAll";
    //state para los datos
    const [dataCarta, setDataCarta] = useState(null)
    const [dataLabels, setDataLabels] = useState(null)
    var request;
    // comprobación del enable
    if (enable){
        // body de la petición
        request = {
            id_establecimiento : id,
            enable : enable
        };
    }else{
        // body de la petición
        request = {
            id_establecimiento : id
        };
    }
    // Opciones para la solicitud
    optionsPost.body = JSON.stringify(request);
    const fetchGetCarta = async  () => {
        try {
            const response = await fetch(path,optionsPost);
            if (!response.ok) {
                throw new Error(`Error al obtener datos : ${response.statusText}`);
            }

            const jsonData = await response.json();
            setDataCarta(jsonData);
            setDataLabels([...new Set(jsonData.map( data => data.label))])
        } catch (error) {
            console.error('Error al obtener datos JSON:', error);
        }
    }
    useEffect(()=>{
        fetchGetCarta();
    },[])

    return {dataLabels,dataCarta}
}
export default useCarta;