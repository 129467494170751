import HeaderMovil from "./movil/HeaderMovil";
import {useEffect, useState} from "react";
import Header from "./escritorio/Header";
import getJson from "../../utils/jsonUtils/getJson";
import getNameJson from "../../utils/jsonUtils/getNameJson";
import {useLocation} from "react-router-dom";

const GestorHeader = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const nameJson = getNameJson(currentPath);
    const data = getJson(nameJson.name);
    const [resize , setResize ] = useState(window.innerWidth);

    const handleResize = (e) => {
        setResize(window.innerWidth);
    }


    useEffect(() => {
        //se añade el event
        window.addEventListener('resize', handleResize);

        return () => {//cuando se desmonta el componente
            window.removeEventListener('resize', handleResize);

        };
    }, [resize]);

    // if(nameJson.name === "lacachimba"){
    //     return "";
    // }
    return(
      <header>
          {
              (data)?
                  (resize <= 855)?
                      <HeaderMovil dataHeader={data}/>
                      :
                      <Header dataHeader={data} currentPath={currentPath}/>
                  :
                  ''
          }
      </header>
    );
}
export default GestorHeader;