
const CompanyImage = (props) => {
    return (
        <div className="col-4 text-center">
            {
                props.data && <img src={require(`../../media/${props.data.url}`)} alt={props.data.altImg} className="image-responsive"/>
            }
        </div>
    );
}
export default CompanyImage;