import {apiKey} from "../../../../conf";

import React from "react";
import PropTypes from "prop-types";
const GoogleMapsEmbed = (props) =>{
    const position = props.dataMap.position;
    const query = "https://www.google.com/maps/embed/v1/place?key="+apiKey+
        "&q="+position.name;

    return(
        <div className="map">
            <iframe
                className="google-map-embed"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={query}>
            </iframe>
        </div>
    )

}
GoogleMapsEmbed.propTypes = {
    dataMap:PropTypes.object.isRequired
}
export default GoogleMapsEmbed;