
export const optionsPost = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    }
}

export const optionsGet = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    }
}