import getDiaHabil from "../../../../utils/jsonUtils/getDiaHabil";

const initToogle = (menus,especial) =>{

    const defaultMenuEspecial = menus.keys().next().value;
    const currentDate = getDiaHabil();
    var initOption = {};
    if (especial){
        initOption = { nombre: menus.get(defaultMenuEspecial).nombre, value: defaultMenuEspecial }
    }else {
        initOption = { nombre: menus.get(currentDate).nombre, value: currentDate }
    }

    return {initOption}
}

export default initToogle;