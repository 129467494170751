import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import CartaProvider from "./providers/CartaProvider";
import {HelmetProvider} from "react-helmet-async";
import CookiesProvider from "./providers/CookiesProvider";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
          <CookiesProvider>
              <CartaProvider>
                  <BrowserRouter>
                      <App />
                  </BrowserRouter>
              </CartaProvider>
          </CookiesProvider>
      </HelmetProvider>
  </React.StrictMode>
);
