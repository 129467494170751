import React from "react";

const SecctionMenu = (props) => {
    return(
        <React.Fragment>
            {
                props.productos.map((producto,index) => (
                    (producto.ordenProducto === props.label)?
                        <div key={index}>
                            <p className="text-small2 text-bold-1">{producto.nombre}</p>
                        </div>
                        :
                        ''
                ))
            }
        </React.Fragment>
    );
}
export default SecctionMenu;