import Links from "./Links";
import CompanyImage from "./CompanyImage";
import SocialMediaLinks from "./SocialMediaLinks";
import {useLocation} from "react-router-dom";
import React from "react";
import getNameJson from "../../utils/jsonUtils/getNameJson";
import './footer.scss'
import getJson from "../../utils/jsonUtils/getJson";
import {motion} from "framer-motion";

const Footer = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    //El getNameJSON comprueba si la url es la de un restaurante o es la de la página home y devuelve un objeto con el nombre del restaurante o el nombre del home
    const nameJson = getNameJson(currentPath);
    const data = getJson(nameJson.name);

    // if(nameJson.name === "lacachimba"){
    //     return "";
    // }
    return(
        <footer className="container">
            <motion.div className="row"
                        initial={{ opacity: 0, y: -180 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            // ease: "easeInOut",
                            duration: 1.5,
                            delay: 5,
                        }}>
                {
                    (data)?
                        <React.Fragment>
                            <Links data = {data.footer.enlacesFooter}/>
                            <CompanyImage data = {data.footer.imageLogo}/>
                            <SocialMediaLinks data = {data.footer.SocialMedia}/>
                        </React.Fragment>
                        :
                        <React.Fragment/>
                }
            </motion.div>
        </footer>
    );
}
export default Footer;