import React, { useRef, useState } from "react";
import { motion } from 'framer-motion';
import getDataForms from "../../../utils/dataForms/getDataForms";
import postContact from "../../../utils/Apis/Forms/postContact";
import { useNavigate } from "react-router-dom";
import clearInputs from "../../../utils/dataForms/clearInputs";

const ContactForm = (props) => {
    const navigateToURL = useNavigate();
    const formRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({
        error: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setErrors({ ...errors, [id]: '' }); // Limpia el error cuando se cambia el valor del campo
    };

    const sendInfoContact = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!formData.name || !formData.email || !formData.message) {
            newErrors.error = 'Asegúrese de completar todos los campos antes de enviar el mensaje';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        if (formRef.current) {
            setShowPopup(true);
            const infoarray = getDataForms(formRef.current);
            postContact(props.idRest, infoarray[0], infoarray[1], infoarray[2])
                .then(() => {
                    clearInputs(formRef.current);
                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    });
                })
                .catch(() => {
                    setErrors({ ...errors, error: 'Hubo un problema al enviar el mensaje. Por favor, inténtelo de nuevo.' });
                });
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setFormData({
            name: '',
            email: '',
            message: ''
        });
        setErrors({
            name: '',
            email: '',
            message: '',
            error: ''
        });
    };

    return (
        <React.Fragment>
            {showPopup && (
                <div className="popup">
                    <div className="divbuttonx">
                        <motion.button onClick={handleClosePopup}
                                       className="button-close"
                                       whileHover={{ scale: 1.1 }}>
                            x
                        </motion.button>
                    </div>
                    <p>¡Mensaje enviado con éxito!</p>
                    <div>
                        <motion.button onClick={() => navigateToURL(props.rutaHome)}
                                       className="button-attach"
                                       whileHover={{ scale: 1.2 }}
                                       transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                            Volver al menú
                        </motion.button>
                    </div>
                </div>
            )}
            {!showPopup && (
                <div className="divForm">
                    <form id="formContact" ref={formRef} onSubmit={sendInfoContact}>
                        <h1 className="font-format-Outfit">Contacto</h1>
                        <div>
                            <label className="labelstyle" htmlFor="name">Nombre</label>
                            <input
                                className="inputStyle"
                                id="name"
                                placeholder="Tu nombre"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="labelstyle" htmlFor="email">Correo</label>
                            <input
                                className="inputStyle"
                                id="email"
                                placeholder="Tu correo electrónico"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label className="labelstyle" htmlFor="message">Mensaje</label>
                            <textarea
                                id="message"
                                placeholder="Tu mensaje"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="privacyPolicy"
                                name="privacyPolicy"
                                value={formData.privacyPolicy}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="privacyPolicy">He leído y acepto la <a href="/privacy" target="_blank">política de privacidad</a></label>
                        </div>
                        <div className="center-item">
                            <motion.button
                                className="button-attach"
                                whileHover={{ scale: 1.2 }}
                                transition={{ type: "spring", stiffness: 1000, damping: 5 }}
                                type="submit"
                                required
                            >
                                Enviar
                            </motion.button>
                        </div>
                        {errors.error && <p className="error text-small3">{errors.error}</p>}
                    </form>

                </div>
            )}
        </React.Fragment>
    );
};

export default ContactForm;
