import DropDown from "../../../Toggles/DropDown";
import LogoHeader from "../../../header/LogoHeader";
import React, {useEffect, useState} from "react";
import header from "../../../header/escritorio/Header";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";
const HeaderLayoutMenu = (props) => {
    const location = useLocation();
    const data = props.dataHeader;
    const [resize , setResize ] = useState(window.innerWidth);
    var headerMovStyles = null;
    var headerDeskStyles = null;
    //acción del toggle:
    const typeMenu = location.pathname.split("/")[3];


    const handleResize = (e) => {
        headerMovStyles = document.querySelector(".header-movil");
        headerDeskStyles = document.querySelector(".header");
        if(headerMovStyles && headerMovStyles.style.display !== "none"){
            headerMovStyles.style.display = "none";
        }
        if (headerDeskStyles && headerDeskStyles.style.position !== "relative"){
            headerDeskStyles.style.position = "relative";
        }
    }
    useEffect(() => {
        handleResize();
        const handleResizeforWindow = (e) => {
            setResize(window.innerWidth);
        }

        //se añade el event
        window.addEventListener('resize', handleResizeforWindow);
        window.addEventListener('resize', handleResize);

        return () => {//cuando se desmonta el componente
            window.removeEventListener('resize', handleResizeforWindow);
            if(headerMovStyles){
                headerMovStyles.style.display= "flex";
            }
            if (headerDeskStyles){
                headerDeskStyles.style.position = "fixed";
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [resize]);
    const changeDayHandle = (e) =>{
        props.setToggleOption({nombre :props.menus.get(e.target.value).nombre, value: e.target.value})
    }
    const options = []

    props.menus.forEach((menu,id)=>{
        let obj = {
            text:menu.nombre,
            value:id
        }
        options.push(obj)
    })
    return (
        <header>
            <div className="container-header">
                {
                    (resize <= 855) ? (
                        <React.Fragment>
                            <LogoHeader img={data.header.headerImage}/>
                        </React.Fragment>
                    ) : (
                        (typeMenu == "menu") ? (
                            <h1>Menú del {props.nameMenu}</h1>
                            ) : (
                                <h1>{props.nameMenu}</h1>
                            )

                    )
                }
                <div className="toggle-drop-down-menu">
                    <DropDown options={options}
                              action={changeDayHandle}
                              classNameUl="ul-toggle-drop-down-menu"
                              classNameLi="li-toggle-drop-down-menu"
                              colorBackgroundButton="#e5e7e8"
                              colorBackgroundToggle="#e5e7e8"
                              colorToggle="#5f707dff"
                    />
                </div>
            </div>
            {
                (resize <=855)?
                    <div>
                        <h1>{props.nameMenu}</h1>
                    </div>
                    :
                    ''
            }
        </header>
    );
}
HeaderLayoutMenu.propTypes = {
    menus: PropTypes.any.isRequired,

}
export default HeaderLayoutMenu;