import LinkSocial from "./LinkSocial";
const SocialMedia = (props) => {
    return (
        <div className="col-4 text-center" >
            <div className="row">
                {
                    props.data && props.data.map((link,index) => (
                        <div className="col-1" key= {index}>
                            <LinkSocial urlLink = {link.url} imgsSrc = {require(`../../media/${link.srcImg}`)}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
export default SocialMedia;