import React from "react";
const ContactText = (props) => {

    return(
        <React.Fragment>
            <div className="divtext">
                <h1>
                    Información
                </h1>
                <div className="divInfo">
                    <h2>
                        Número de teléfono
                    </h2>
                    <p>
                        {props.cont.phone}
                    </p>
                </div>
                <div className="divInfo">
                    <h2>
                        Email
                    </h2>
                    <p>
                        {props.cont.mail}
                    </p>
                </div>
                <div className="divInfo">
                    <h2>
                        Dirección
                    </h2>
                    <p>
                        {props.cont.address}
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactText;