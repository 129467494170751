import PropTypes from "prop-types";
import React, {useContext} from "react";
import imgCookie from '../../media/cookie.svg'
import setLocalStorage from "../../repositories/localStorage/setLocalStorage";
import {COOKIES_NAME} from "../../utils/Constants/ConstantsStorage";
import {CookiesContext} from "../../providers/CookiesProvider";
import './cookies.scss'
const SectionCookies = (props) => {
    const {setCookies} = useContext(CookiesContext)
    const section = props.nameSection? props.nameSection: 'está sección';
    const aceptarHandle = (e) => {
        const hoy = new Date();
        setLocalStorage(COOKIES_NAME, hoy)
        setCookies(true);
    }
    return(
        <div className="section-cookies">
            <div>
                <h2 className="title-text">
                    Aviso de Cookies Necesarias
                </h2>
                <p>
                    Estas cookies son esenciales para el funcionamiento de {section}. <br/>
                    Para más información  visita nuestras <a href="/privacy">politica de privacidad</a>
                </p>
            </div>
            <div className="section-img-buttons-cookies">
                <div className="img-cookie">
                    <img src={imgCookie} alt="cookie" />
                </div>
                <div className="btn-acept-cookie">
                    <button className="btn-acept" onClick={aceptarHandle}>Aceptar cookies Necesarias</button>
                </div>
            </div>
        </div>
    );
}
SectionCookies.propTypes = {
    nameSection: PropTypes.string
}
export default SectionCookies;