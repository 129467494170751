import {LACACHIMBA,SANIGNACIO,HOME,HOME_EMPTY,HOME_SLASH} from "../Constants/NameConstants";
import homeJSON from "../../Recursos/home.json";
import laCachimbaJSON from "../../Recursos/lacachimba.json";
import sanIgnacioJSON from "../../Recursos/sanignacio.json";
const getJson = (name) => {
    var sendJSON;
    switch (name){
        case LACACHIMBA:
            sendJSON = laCachimbaJSON;
            break;
        case SANIGNACIO:
            sendJSON = sanIgnacioJSON;
            break;
        case HOME:
            sendJSON = homeJSON;
            break;
        case HOME_SLASH:
            sendJSON = homeJSON;
            break;
        case HOME_EMPTY:
            sendJSON = homeJSON;
            break;
        default:
            // return window.location.href='/404'
            break;

    }
    return sendJSON;
}
export default getJson;