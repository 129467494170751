import privacyJson from "../privacy.json";

const getIndice = () =>  {
    var indice = []
    for (const apartados of privacyJson) {
        indice.push(
            <li>
                <p><a className="title-text" href={"#"+apartados.id}>{apartados.subtitulo}</a></p>
            </li>
        )
    }
    return indice;
}
export default getIndice;