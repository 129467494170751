import privacyJson from "../privacy.json";
import getListasPrivacy from "./getListasPrivacy";

const getApartadosPrivacy = () => {
    const apartadosPrivacy = [];
    for (const apartados of privacyJson) {
        let hasLista = apartados.lista? apartados.lista: false
        apartadosPrivacy.push(
            <div>
                <h2 id={apartados.id} className="title-text title-ancla">{apartados.subtitulo}</h2>
                {apartados.descripcion?<p className="title-text">{apartados.descripcion}</p>:''}
                {getListasPrivacy(0,hasLista)}
                {apartados.descripcionEnd?<p className="title-text">{apartados.descripcionEnd}</p>:''}
            </div>
        )
    }
    return apartadosPrivacy;
}
export default getApartadosPrivacy;