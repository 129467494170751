import React from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const LogoHeader = (props) => {
    const isMovil = props.isMovil? props.isMovil:false;
    return (
        <div className={isMovil?"header-logo-movil":"header-logo"}>
            { props.img &&
                <Link to={props.img.url}>
                <img src={require(`../../media/${props.img.src}`)} alt="Logo principal"/>
                </Link>}
        </div>

    )
}
LogoHeader.propTypes = {
    img: PropTypes.object.isRequired,
    isMovil: PropTypes.bool
}
export default LogoHeader;
