import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import "./home.scss"
import goToAnchorTimeOut from "../../../utils/jsonUtils/goToAnchorTimeOut";


const SelectRestaurants = () => {
    const refSelectResSection = useRef(null);
    const refTimeout = useRef(null);

    useEffect(  ()=>{

        //función creado para ir a la ancla durante el cambio de la página recibe la referencia del elemento o la ancla
        refTimeout.current =  goToAnchorTimeOut(refSelectResSection.current); // te devuelve el identificador del timeOut para que puedas destruir si el componente se destruye
        return () => {
            clearTimeout(refTimeout.current)
        }
    },[])

    return (
        <React.Fragment>
            <div id="restaurantes" ref={refSelectResSection}>
                <section id="slide" className="img-animation">
                    <Link to="/restaurant/sanignacio" className="text-decoration-none">
                        <div id="sanin" className="slide-item divTextCard">
                            <p className="font-format-Gochi">San Ignacio</p>
                        </div>
                    </Link>
                    <Link to="/restaurant/lacachimba" className="text-decoration-none">
                        <div id="cachimba" className="slide-item divTextCard">
                            <p className="font-format-Gochi">La Cachimba</p>
                        </div>
                    </Link>
                </section>
            </div>
        </React.Fragment>
    );
};

export default SelectRestaurants;
