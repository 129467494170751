const validacionCookiesFecha = (fechaComprobar) => {
    // Convertir la fecha recibida a un objeto Date
    var fecha = new Date(fechaComprobar);

    // Obtener la fecha actual
    var hoy = new Date();

    // Calcular la diferencia en años y meses
    var ageDiferencia = fecha.getFullYear() - hoy.getFullYear();
    var mesesDiferencia = fecha.getMonth() - hoy.getMonth();

    // Calcular la diferencia total en meses
    var diffMeses = (ageDiferencia * 12) + mesesDiferencia;

    // Comparar la diferencia con 12 meses
    return diffMeses < 12;
}
export default validacionCookiesFecha;