import React from "react";
import {useState} from "react";
import LoadingScreen from "../../LoadingScreens/LoadingScreen";
import SectionCarruselTop from "../sectionCarruselTop/SectionCarruselTop";
import getJson from "../../../utils/jsonUtils/getJson";
import {Helmet} from "react-helmet-async";
import DescriptionMainHome from "./DescriptionMainHome";

const Main = (props) => {
    const name = props.name;
    const data = getJson(name);
    const [loading ,setLoading] = useState(true);


    return(

        <main>
            <Helmet>
                <link rel="canonical" href={"https://www.grupocasapijo.es/"}/>
                <title>Auténtica gastronomía asturiana en Madrid | Grupo Casa Pijo</title>
                <meta name="description" content="¿Buscas el mejor cachopo de Madrid? Visita los restaurantes asturianos del Grupo Casa Pijo, gastronomía asturiana de la mayor calidad." />
                <meta property="og:title" content="Auténtica gastronomía asturiana en Madrid | Grupo Casa Pijo" />
                <meta property="og:description" content="¿Buscas el mejor cachopo de Madrid? Visita los restaurantes asturianos del Grupo Casa Pijo, gastronomía asturiana de la mayor calidad." />
                <meta name="keywords" content="restaurante asturiano, restaurante asturiano en Madrid, asturianos en Madrid, comida asturiana, sidreria asturiana, sidreria san Ignacio, mejor cachopo Madrid, mejores cachopos de Madrid, gastronomia asturiana, comer cachopo en Madrid, el mejor cachopo de Madrid, restaurante san Ignacio, sidreria asturiana Madrid, sidreria la cachimba, bar san Ignacio, restaurante la cachimba, bar la cachimba" />
                <meta property="og:type" content="website" />
            </Helmet>

            {
                (!loading&&data) ?
                    <React.Fragment>
                        <SectionCarruselTop images={data.main.sectionCarrusel.images}
                                            imageCenter={data.main.sectionCarrusel.imageCenter}/>
                        <DescriptionMainHome
                            data = {data.main.sectionDescription}
                            buttonurl={data.main.sectionDescription.urlButton}/>
                    </React.Fragment>
                :
                    <LoadingScreen call={setLoading}/>
            }
        </main>
    );
}
export default Main;