import {urlbackendcomunicaciones} from "../../../conf";
import {optionsPost} from "../optionsMethods";



const postContact =async (id, nombre, correo, mensaje) =>{
    // ruta de la petición
    const path = urlbackendcomunicaciones+"forms/contacto";
    const request = {
        id_establecimiento: id,
        nombre: nombre,
        correo_electronico: correo,
        mensaje: mensaje
    };

    // Opciones para la solicitud
    optionsPost.body = JSON.stringify(request);

    try {
        const response = await fetch(path,optionsPost);
        if (!response.ok) {
            throw new Error(`Error al obtener datos : ${response.statusText}`);
        }

        const jsonData = await response.json();
        return jsonData

    } catch (error) {
        console.error('Error al obtener datos JSON:', error);
    }

    return null
}
export default postContact;