import {useContext, useEffect, useState} from "react";
import {CookiesContext} from "../../providers/CookiesProvider";
import React from "react";
import setLocalStorage from "../../repositories/localStorage/setLocalStorage";
import {COOKIES_NAME} from "../../utils/Constants/ConstantsStorage";
import getLocalStorage from "../../repositories/localStorage/getLocalStorage";
import validacionCookiesFecha from "../../utils/jsonUtils/validacionCookiesFecha";
import './cookies.scss'
import {motion} from "framer-motion";
import {PRIVACY_ROUTE} from "../../utils/Constants/NameConstants";
import {useLocation} from "react-router-dom";
const showVariant = {
    hidden: { opacity: 0},
    show: { opacity: 1 ,transition: {duration: 1},delay:3},
}
const CookiesBanner = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const {cookies, setCookies} = useContext(CookiesContext)
    const cookiesLocalStorage = getLocalStorage(COOKIES_NAME);
    if (cookiesLocalStorage != null && validacionCookiesFecha(cookiesLocalStorage)){
        setCookies(true)
    }
    const aceptarHandle = (e) => {
        const hoy = new Date();
        setLocalStorage(COOKIES_NAME, hoy)
        setCookies(true);
    }
    const rechazarHandle = (e) => {
        setCookies(false);
    }
    if (cookies === null){
        return (
            <React.Fragment>
                <motion.div className={(currentPath === PRIVACY_ROUTE)? 'hidden-background-bannercookies':'cookies-banner' } variants={showVariant} initial="hidden" animate="show"/>
                <motion.div className="banner" variants={showVariant} initial="hidden" animate="show">
                    <div className="text-banner">
                        <h3 className="title-text">Este sitio web utiliza cookies para una mejor experiencia</h3>
                        <p className="text-small4">En nuestro sitio web, utilizamos Google Maps para proporcionar a nuestros usuarios mapas interactivos y servicios de geolocalización.
                            Este servicio es proporcionado por Google Inc.
                            ("Google"). Al utilizar Google Maps en nuestro sitio web, algunas cookies pueden ser instaladas en tu dispositivo por Google para más información en nuestras
                            <a href="/privacy"> Política de privacidad</a> .</p>
                    </div>
                    <div className="buttons">
                        <button className="btn-acept" onClick={aceptarHandle}>Aceptar todas las cookies</button>
                        <button className="btn-danger"  onClick={rechazarHandle}>Rechazar todas las cookies</button>
                    </div>
                </motion.div>
            </React.Fragment>
        );
    }else{
        return <React.Fragment/>
    }

}

export default CookiesBanner;