import {urlbackend} from "../../../conf";
import {optionsPost} from "../optionsMethods";
import {useEffect, useState} from "react";

const useMenuAll = (idEstablecimiento,espacial) => {
    const [dataMenu,setDataMenu] = useState(null);
    // ruta de la petición
    const path = urlbackend+"productos/menu/getAll";
    const  request = {
        id_establecimiento: idEstablecimiento,
        enable: true
    };
    // Opciones para la solicitud
    optionsPost.body = JSON.stringify(request);

    const fetchGetMenuAll = async () => {
        const menusExist = new Map();
        try {
            const response = await fetch(path,optionsPost);
            if (!response.ok) {
                throw new Error(`Error al obtener datos : ${response.statusText}`);

            }
            const jsonData = await response.json();
            jsonData.map((menu,index) => {
                if (espacial){
                    if (menu.idMenu > 5 ){
                        menusExist.set(menu.idMenu,{nombre:menu.nombre, precio:menu.precio});
                    }
                }else {
                    if (menu.idMenu <= 5 ){
                        menusExist.set(menu.idMenu,{nombre:menu.nombre, precio:menu.precio});
                    }
                }

            })
            setDataMenu(menusExist);

        } catch (error) {
            console.error('Error al obtener datos JSON:', error);
        }
    }

    useEffect(()=>{
        fetchGetMenuAll();
    },[])

    return {dataMenu}
}
export default useMenuAll;