const OptionsHeader = (props) => {


    return (
        <div className="menu">
            {
                props.links && props.links.map((link, index) => (
                    <a key={index} href={link.url} className="text-decoration-none text-white enlacesheader text-small3">
                        <div className="menu-item abel-regular text-medium">
                            {link.name}
                        </div>
                    </a>
                ))
            }
        </div>
    );
}
export default OptionsHeader;