const getNameJson = (url) =>{
    const regex =/\/restaurant\/([^\/]+)/;
    const match = url.match(regex);
    var namejson = {};
    if (match){
       namejson.name = match[1];
        namejson.type= 'RES'
    }else{
        if (url==='/' || url==='' ){
            namejson.name = 'home';
            namejson.type= 'INI'
        }else {
            namejson.name = 'home';
            namejson.type= 'INI'
        }
    }
    return namejson;

}
export default getNameJson;