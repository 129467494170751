import React from 'react';
import './LoadingScreenSection.scss';

function LoadingScreenSection() {

    return (
        <div className="loader-wrapperSection">
            <div className="loaderSection"></div>
        </div>
    );
}

export default LoadingScreenSection;
