import './privacy.scss'
import getIndice from "./utils/getIndice";
import getApartadosPrivacy from "./utils/getApartadosPrivacy";
import dataHome from '../../../Recursos/home.json'
import {Helmet} from "react-helmet-async";
import React from "react";

const Privacy = () => {
    const apartadosPrivacy = getApartadosPrivacy();
    const indice = getIndice();
    return(
     <main className="main-privacy">
         <Helmet>
             <link rel="canonical" href={"https://www.grupocasapijo.es/privacy/"}/>
             <title>{dataHome.helmets.helmetPrivacy.titulo}</title>
             <meta name="description" content= {dataHome.helmets.helmetPrivacy.descripcion}/>
             <meta property="og:title" content= {dataHome.helmets.helmetPrivacy.titulo}/>
             <meta property="og:description" content= {dataHome.helmets.helmetPrivacy.descripcion}/>
             <meta name="keywords" content= {dataHome.helmets.helmetPrivacy.keywords}/>
             <meta property="og:type" content="website" />
         </Helmet>
         <section className="section-privacy">
             <h1 className="title-text">Politica de Privacidad</h1>
             <h2>Índice</h2>
             <ul>{indice}</ul>
             {apartadosPrivacy}
         </section>
     </main>
    )
}
export default Privacy;