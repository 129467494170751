import {urlbackendservicios} from "../../../conf";
import {optionsGet} from "../optionsMethods";
import {useEffect, useState} from "react";

const usePlaceInfo = (idEstablecimiento) => {
    const [dataPlaceInfo,setDataPlaceInfo] = useState(null);
    // ruta de la petición
    const path = `${urlbackendservicios}servicios/getInfo?placeId=${idEstablecimiento}`;

    const fetchGetPlaceInfo = async () => {
        try {
            const response = await fetch(path,optionsGet);
            if (!response.ok) {
                throw new Error(`Error al obtener datos : ${response.statusText}`);
            }

            const jsonData = await response.json();
            setDataPlaceInfo(jsonData);

        } catch (error) {
            console.error('Error al obtener datos JSON:', error);
        }
    }

    useEffect(()=> {
        fetchGetPlaceInfo();
    },[])

    return {dataPlaceInfo}
}

export default usePlaceInfo;