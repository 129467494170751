import './home.scss'
import {motion} from "framer-motion";
import React, {useEffect, useRef} from "react";
import SelectRestaurant from "./SelectRestaurant";
import {changePage} from "../../../utils/jsonUtils/functionsButtons";
import goToAnchorTimeOut from "../../../utils/jsonUtils/goToAnchorTimeOut";
const DescriptionMainHome = (props) => {
    const refQuienesSomosSection = useRef(null);
    const refTimeout = useRef(null);

    useEffect(  ()=>{

        //función creado para ir a la ancla durante el cambio de la página recibe la referencia del elemento o la ancla
        refTimeout.current =  goToAnchorTimeOut(refQuienesSomosSection.current); // te devuelve el identificador del timeOut para que puedas destruir si el componente se destruye
        return () => {
            clearTimeout(refTimeout.current)
        }
    },[])
    return (
        <div id="description" ref={refQuienesSomosSection} className="description-containerHome">
            <div>
                <h1 className="description-headingHome font-format-Gochi title-section">
                    {props.data.title}
                </h1>
                <div className="description-contentHome">
                    <pre className="description-textHome font-format-Gochi text-small3">
                        {props.data.description}
                    </pre>
                </div>
            </div>
            <div>
                <h2 className="description-headingHome font-format-Gochi title-section">
                    {props.data.h2rest}
                </h2>
                <div className="description-contentHome">
                    <p className="description-textHome font-format-Gochi text-small3">
                        {props.data.descriptionRest}
                    </p>
                </div>
                <SelectRestaurant/>
            </div>

            <div className="eventsDiv">
                <h3 className="description-headingHome font-format-Gochi title-section">
                    {props.data.eventsTitle}
                </h3>
                <div className="events-descriptionHome">
                    <p className="description-textHome font-format-Gochi text-small3">
                        {props.data.eventsDescription}
                    </p>
                    <motion.button onClick={() => changePage(props.data.urlButton)}
                                   className="buttonDescriptionHome font-format-Gochi"
                                   whileHover={{ scale: 1.05 }}
                                   transition={{ type: "spring", stiffness: 1000, damping: 5 }}>
                        Contacta con nosotros
                    </motion.button>
                </div>
            </div>


            {/*SECCION RESERVAS, PENDIENTE DE INTEGRAR FUNCIONALIDAD DE RESERVAS*/}
            {/*<h2 className="description-heading font-format-Gochi title-section">*/}
            {/*    {props.data.reservationTitle}*/}
            {/*</h2>*/}
            {/*<div className="description-content">*/}
            {/*    <p className="description-text font-format-Gochi text-small3">*/}
            {/*        {props.data.reservationDescription}*/}
            {/*    </p>*/}
            {/*</div>*/}



            {/*SECCION CONOCENOS, SIN DESARROLLO PENDIENTE POR EL MOMENTO*/}
            {/*<div className="button-container">*/}
            {/*    <motion.button onClick={() => changePage(props.buttonurl)} className="button-attach font-format-Gochi buttonDescription" whileHover={{ scale: 1.2 }} transition={{ type: "spring", stiffness: 1000, damping: 5 }}>*/}
            {/*        Conócenos!*/}
            {/*    </motion.button>*/}
            {/*</div>*/}
        </div>

    );
}
export default DescriptionMainHome;